<template>
  <div>
    <router-link class="come-back" to="/admin/corruption">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">
      {{ $route.query.type === 'edit' ? 'Редактирование пункта коррупции' : 'Создание пункта коррупции' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddCorruption" ref="AddCorruption">
      <div class="content-sb">
        <p>Название пункта<span>*</span></p>
        <input required placeholder="Введите название пункта" v-model="form.title" type="text" name="title"
               class="input blue">
      </div>
      <div class="content-sb">
        <p>Название пункта (Англ)</p>
        <input placeholder="Введите название пункта (Англ)" v-model="form.titleEng" type="text" name="titleEng"
               class="input blue">
      </div>

      <div v-if="$route.query.type !== 'edit'" class="content-sb">
        <p>Секция<span>*</span></p>
        <select required @change="changeGroup" class="input blue" name="SectionId">
          <option v-if="$route.query.type === 'create'" disabled selected>Выберите секцию</option>
          <option v-for="item in $store.getters.getCorruptionGroup"
                  :key="`getCorruptionGroup${item.id}`" :value="item.id">
            {{ item.title }}
          </option>
        </select>
      </div>

      <div class="content-sb">
        <p>Тип группы<span>*</span></p>
        <select required @change="changeType" class="input blue">
          <option v-if="$route.query.type === 'create'" disabled selected>Выберите тип группы</option>
          <option value="1" :selected="form.is_file === true">Группа документов</option>
          <option value="0" :selected="form.is_file === false">Ссылка</option>
        </select>
      </div>
      <div v-if="!form.is_file && form.is_file !== null" class="content-sb">
        <p>Ссылка на страницу<span>*</span></p>
        <input placeholder="Вставьте ссылку на сторонний ресурс" name="link" v-model="form.link" type="text" class="input blue">
      </div>

      <div v-if="form.is_file && form.is_file !== null" class="content-sb">
        <p>Документ<span>*</span></p>
        <input type="file" name="file" class="input blue">
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/corruption" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createCorruption',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailСorruption : {
        title: '',
        titleEng: '',
        link: '',
        is_file: null,
        groupId: null
      }
    }
  },
  methods: {
    AddCorruption () {
      const formData = new FormData(this.$refs.AddCorruption)
      if (this.form.link) formData.append('link', this.form.link)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editСorruption', { data: formData, id: this.form.id })
      } else {
        formData.append('is_file', this.form.is_file)
        this.$store.dispatch('createСorruption', { data: formData, id: this.form.groupId })
      }
    },
    changeType (e) {
      this.form.is_file = Number.parseInt(e.target.value)
    },
    changeGroup (e) {
      this.form.groupId = e.target.value
    }
  },
  mounted () {
    this.$store.dispatch('setCorruptionGroup')
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailСorruption === null) {
      this.$router.push('/admin/corruption')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
